import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { C12MediaAnimationImageProps } from './types';

gsap.registerPlugin(ScrollTrigger);

const C12MediaAnimationBlock = ({ children, height }) => (
	<div className='c12-media-animation' style={{height: height}}>
		{children}
	</div>
);

const C12MediaAnimationImage = ({ filename, alt }: C12MediaAnimationImageProps ) => {
	return (
		<>
			{filename &&
				<img className={'c12-media-animation__image'} src={filename + '/m/'} alt={alt} />
			}
		</>
	);
};

export {
	C12MediaAnimationBlock,
	C12MediaAnimationImage,
};
