import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link';
import React, { useRef } from 'react';
import { curtainAnimation } from '../../utils';

const C9LinksModuleBlock = ({ children }) => (
	<div className='c9-links-module'>
		{children}
	</div>
);

const C9LinksModuleTitle = ({ children }) => (
	<h1 className='c9-links-module__title'>
		{children}
	</h1>
);

const C9LinksModuleContainer = ({ children }) => (
	<div className='c9-links-module__link-container'>
		{children}
	</div>
);

const C9LinksModuleImageLink = ({ children, link }) => {
	const coverRef = useRef<HTMLDivElement>();

	return (
		<>
			{link.url ?
				<a href={link.url} target='_blank' rel='noreferrer noopener'>{children}</a>
				:
				link.cached_url &&
				<>
					<TransitionLink
						to={`/${link.cached_url}`}
						exit={
							{
								length: 1,
								trigger: ({ exit, node }) => {
									curtainAnimation({
										props: exit,
										node,
										transitionDirection: 'up',
									}, coverRef.current);
								},
							}

						}
						entry={{ delay: 1 / 2 }}>
						{children}
					</TransitionLink>
					<TransitionPortal>
						<div ref={coverRef} className="transition-portal" />
					</TransitionPortal>
				</>
			}
		</>
	);
};

const C9LinksModuleFigure = ({ children }) => (
	<figure className='c9-links-module__figure'>
		{children}
	</figure>
);

const C9LinksModuleImage = ({ image }) => {
	return (
		<>
			{image?.filename &&
				<img className='c9-links-module__image' src={image.filename + '/m/'} alt={image?.alt} />
			}
		</>
	);
};

const C9LinksModuleMobileScroller = ({ children }) => (
	<div className='c9-links-module__mobile-scroller'>
		{children}
	</div>
);

export {
	C9LinksModuleBlock,
	C9LinksModuleTitle,
	C9LinksModuleContainer,
	C9LinksModuleImageLink,
	C9LinksModuleFigure,
	C9LinksModuleImage,
	C9LinksModuleMobileScroller,
};
