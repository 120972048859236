import React, {
	PropsWithChildren,
	useRef,
	useLayoutEffect,
	useEffect,
	useState,
} from 'react';
import { C7MediaBannerImageProps, C7MediaBannerVideoProps } from './types';
import clsx from 'clsx';
import { useFileSource } from '../../hooks';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const C7MediaBanner = ({ children, hasFrame }: PropsWithChildren<{hasFrame: boolean}> ) => (
	<div className={clsx('c7-media-banner', {'c7-media-banner--framed': hasFrame})}>{children}</div>
);

const C7MediaBannerImage = ({ filename, alt }: PropsWithChildren<C7MediaBannerImageProps>) => {

	return (
		<>
			{filename &&
				<img className='w-full aspect-video' src={filename + '/m/'} alt={alt} />
			}
		</>
	);
};

const C7MediaBannerVideo = ({ filename, freezeOnVideoEnd, assets, fixed }: PropsWithChildren<C7MediaBannerVideoProps>) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const triggerRef = useRef<HTMLDivElement>(null);
	const videoFile = useFileSource(assets, filename);
	const [canPlay, setCanPlay] = useState(false);

	useLayoutEffect(() => {
		if(videoRef.current && canPlay) {
			const videoScrollTrigger = ScrollTrigger.create({
				trigger: triggerRef.current,
				start: 'top 67%',
				end: 'bottom 33%',
				onEnter: () =>  videoRef.current.play(),
				onEnterBack: () => videoRef.current.play(),
				onLeave: () => videoRef.current.currentTime > 0 && videoRef.current.pause(),
				onLeaveBack: () => videoRef.current.currentTime > 0 && videoRef.current.pause(),
			});
			return () => videoScrollTrigger.kill();
		}
	}, [canPlay]);

	useEffect(() => {
		if(videoRef.current) {
			const toggleCanPlay = () => setCanPlay(true);
			videoRef.current?.addEventListener('canplay', toggleCanPlay);
			return () => videoRef.current?.removeEventListener('canplay', toggleCanPlay);
		}
	}, []);

	return (
		<section className='c7-media-banner__section' ref={triggerRef}>
			{fixed && <div className='c7-media-banner__background' />}
			<div className={clsx('c7-media-banner__video-container', {
				'c7-media-banner__video-container--fixed': fixed,
			})}>
				<video
					className='c7-media-banner__video'
					ref={videoRef}
					src={videoFile ? videoFile.node.publicUrl : filename}
					muted
					loop={!freezeOnVideoEnd}
				/>
			</div>
		</section>
	);
};

export {
	C7MediaBanner,
	C7MediaBannerImage,
	C7MediaBannerVideo,
};
