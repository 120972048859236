import React, { PropsWithChildren, useMemo } from 'react';
import { richTextResolver } from '../../utils';
import {
	C3ContentCoverImageWrapperProps,
	C3ContentCoverImageDescriptionProps,
	C3ContentCoverImageExternalLinkProps,
} from './types';
import RichText from '../richtext';

const C3ContentCoverImageBlock = ({ children }: PropsWithChildren<unknown>) => (
	<div className="c3-content-coverimage">
		{children}
	</div>
);

const C3ContentCoverImageContent = ({ children }: PropsWithChildren<unknown>) => (
	<div className="c3-content-coverimage__content">
		{children}
	</div>
);

const C3ContentCoverImageContentWrapper = ({ children }: PropsWithChildren<unknown>) => (
	<div className="c3-content-coverimage__content-wrapper">
		{children}
	</div>	
);

const C3ContentCoverImageWrapper = ({ imageData }: C3ContentCoverImageWrapperProps) => {
	const { alt, filename } = imageData;

	return (
		<div className="c3-content-coverimage__wrapper">
			{filename ? (
				<img src={filename + '/m/'} alt={alt} className="c3-content-coverimage__image" />
			) : null}
		</div>
	);
};

const C3ContentCoverImageTitle = ({ children }: PropsWithChildren<unknown>) => (
	<h1 className="c3-content-coverimage__title">
		{children}
	</h1>
);

const C3ContentCoverImageDescription = ({ text }: C3ContentCoverImageDescriptionProps) => {
	const html = useMemo(() => richTextResolver(text), [text]);

	return (
		<RichText html={html} className="c3-content-coverimage__description" />
	);
};

const C3ContentCoverImageExternalLink = ({ url, children }: PropsWithChildren<C3ContentCoverImageExternalLinkProps>) => (
	<a href={url} target="_blank" rel='noreferrer noopener' className="button button--link button--no-border button--black">
		{children}
	</a>
);

export {
	C3ContentCoverImageBlock,
	C3ContentCoverImageWrapper,
	C3ContentCoverImageContent,
	C3ContentCoverImageTitle,
	C3ContentCoverImageDescription,
	C3ContentCoverImageContentWrapper,
	C3ContentCoverImageExternalLink,
};
