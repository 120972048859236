import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { B1ProductHeroModuleProps } from './types';
import {
	B1ProductHeroBlock,
	B1ProductHeroImageWrapper,
	B1ProductHeroImage,
	B1ProductHeroTitleGroup,
	B1ProductHeroSubtitle,
	B1ProductHeroTitle,
	B1ProductHeroContentWrapper,
	B1ProductHeroContainer,
	B1ProductHeroDescription,
	B1ProductHeroContent,
	B1ProductHeroBottom,
	B1ProductHeroScrollIndicator,
} from './index';
import {
	GridBlock,
	GridContainer,
	GridSpacing,
} from '../../components/grid';
import {
	InternalLinkButton,
	ButtonBackArrow,
} from '../button';
import { useMediaMatchesResize } from '../../hooks';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

const B1ProductHeroModule = ({ blok }: B1ProductHeroModuleProps) => {
	const {
		back_button_label,
		back_button_link,
		background_image,
		background_image_mobile,
		cta_label,
		description,
		disable_foreground,
		foreground_image,
		foreground_image_mobile,
		scroll_indicator_label,
		subtitle,
		title,
	} = blok;
	const isMobile = useMediaMatchesResize('(max-width: 640px)');
	
	useEffect(() => {
		gsap.registerPlugin(ScrollToPlugin);
	}, []);

	const scroll = () => {
		if(window !== undefined) {
			gsap.to(window, {duration: 2, scrollTo: '#d1-block-anchor'});
		}
	};

	return (
		<B1ProductHeroBlock>
			<B1ProductHeroContainer>
				<B1ProductHeroImageWrapper background>
					<B1ProductHeroImage imageData={isMobile ? background_image_mobile : background_image}/>
				</B1ProductHeroImageWrapper>
				<B1ProductHeroTitleGroup>
					<GridSpacing>
						<B1ProductHeroTitle>
							<B1ProductHeroSubtitle>
								{title}
							</B1ProductHeroSubtitle>
							{subtitle}
						</B1ProductHeroTitle>
					</GridSpacing>
				</B1ProductHeroTitleGroup>
				{!disable_foreground && <B1ProductHeroImageWrapper>
					<B1ProductHeroImage imageData={isMobile ? foreground_image_mobile : foreground_image}/>
				</B1ProductHeroImageWrapper>}
			</B1ProductHeroContainer>
			<B1ProductHeroContentWrapper>
				<GridSpacing>
					<GridContainer fullWidth>
						<GridBlock>
							<div className="col-span-full lg:col-span-3 lg:col-start-9">
								<B1ProductHeroContent>
									<B1ProductHeroDescription>
										{description}
									</B1ProductHeroDescription>
									<Link to='#d1-block-anchor' onClick={scroll} className="button button--fullwidth">
										{cta_label}
									</Link>
								</B1ProductHeroContent>
							</div>
						</GridBlock>
					</GridContainer>
				</GridSpacing>
			</B1ProductHeroContentWrapper>
			<B1ProductHeroBottom>
				<GridSpacing>
					<GridContainer>
						<GridBlock>
							<div className="col-span-2">
								<InternalLinkButton
									noBorder
									withArrow
									noHoverEffect
									showCurtainAnimation
									to={'/' + back_button_link.cached_url}
								>
									<ButtonBackArrow />
									{back_button_label}
								</InternalLinkButton>
							</div>
							<div className="col-span-8">
								<B1ProductHeroScrollIndicator>
									{scroll_indicator_label}
								</B1ProductHeroScrollIndicator>
							</div>
						</GridBlock>
					</GridContainer>
				</GridSpacing>
			</B1ProductHeroBottom>
		</B1ProductHeroBlock>
	);
};

export default B1ProductHeroModule;
