import React, { PropsWithChildren } from 'react';
import {
	C10SpecsDownloadLinkProps,
	C10SpecsImageProps,
} from './types';

const C10SpecsBlock = ({ children }: PropsWithChildren<unknown>) => (
	<section className="c10-specs">
		{children}
	</section>
);

const C10SpecsImage = ({ imageData }: PropsWithChildren<C10SpecsImageProps>) => {
	return (
		<>
			{imageData?.filename &&
				<img src={imageData.filename + '/m/'} alt={imageData.alt} className='c10-specs__image' />
			}
		</>
	);
};

const C10SpecsTitle = ({ children }: PropsWithChildren<unknown>) => (
	<p className='c10-specs__title'>
		{children}
	</p>
);

const C10SpecsItem = ({ children }: PropsWithChildren<unknown>) => (
	<div className='c10-specs__spec-item'>
		{children}
	</div>
);

const C10SpecsDownloadLink = ({ children, to }: PropsWithChildren<C10SpecsDownloadLinkProps>) => (
	<a href={to} className='c10-specs__ressources-link' target='_blank' rel='noreferrer'>
		{children}
	</a>
);

const C10SpecsDownload = ({ children }: PropsWithChildren<unknown>) => (
	<div className='c10-specs__ressources-list'>
		{children}
	</div>
);

export {
	C10SpecsBlock,
	C10SpecsDownload,
	C10SpecsDownloadLink,
	C10SpecsImage,
	C10SpecsTitle,
	C10SpecsItem,
};
