import clsx from 'clsx';
import React from 'react';
import { C6ContentBlock, C6ContentBlockLink, C6ContentBlockText, C6ContentBlockHeadline } from '.';
import { ButtonAnchorWrapper } from '../button';
import { GridBlock, GridContainer, GridSpacing } from '../grid';

const C6ContentBlockModule = ({ blok }) => {
	const {
		text,
		link,
		label,
		headline,
	} = blok;
	return (
		<C6ContentBlock>
			<GridSpacing>
				<GridContainer>
					<GridBlock>
						<div className={clsx({
							'col-span-6 md:col-start-2 md:col-span-6': link.cached_url || (!link.cached_url && !text),
							'col-span-6 md:col-start-3 md:col-span-6': !link.cached_url && text,
						})}>
							<C6ContentBlockHeadline>{headline}</C6ContentBlockHeadline>
							<C6ContentBlockText>{text}</C6ContentBlockText>
							{link?.url &&
								<ButtonAnchorWrapper black>
									<C6ContentBlockLink linkItem={{ label: label, url: link }} />
								</ButtonAnchorWrapper>
							}
						</div>
					</GridBlock>
				</GridContainer>
			</GridSpacing>
		</C6ContentBlock>
	);
};

export default C6ContentBlockModule;
