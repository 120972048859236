import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import clsx from 'clsx';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
	GridBlock,
	GridSpacing,
	GridContainer,
} from '../grid';
import {
	C2ContentImageBlock,
	C2ContentImageTitle,
	C2ContentImageFigure,
	C2ContentImageElement,
	C2ContentImageFigureCaption,
	C2ContentImageDescription,
} from './index';

gsap.registerPlugin(ScrollTrigger);

const C2ContentImageModule = ({ blok }) => {
	const {
		title,
		first_image,
		second_image,
		description,
		extra_top_spacing,
		image_left,
		first_image_on_top,
	} = blok;
	const rootRef = useRef();

	const createParallaxEffect = () => {
		const root = gsap.utils.selector(rootRef);

		if (root('.parallax-slow').length > 0) {
			ScrollTrigger.create({
				trigger: root('.parallax-slow'),
				animation: gsap.fromTo(root('.parallax-slow'), {
					y: 50,
				}, {
					y: -50,
					ease: 'Power0.easeNone',
				}),
				start: 'top bottom',
				end: 'bottom 200px',
				scrub: true,
			});
		}

		if (root('.parallax-quick').length > 0)	{
			ScrollTrigger.create({
				trigger: root('.parallax-quick'),
				animation: gsap.fromTo(root('.parallax-quick'), {
					y: 50,
				}, {
					y: -75,
					ease: 'Power0.easeNone',
				}),
				start: 'top bottom',
				end: 'bottom 200px',
				scrub: true,
			});
		}
	};

	useEffect(() => {
		createParallaxEffect();
	}, []);

	if (second_image.filename) {
		return (
			<div ref={rootRef}>
				<C2ContentImageBlock doubleImages extraTopSpacing={extra_top_spacing}>
					<GridSpacing>
						<GridContainer>
							<GridBlock>
								<div className='col-span-full md:col-start-8 md:col-span-4 md:order-none'>
									<C2ContentImageDescription text={description} rightIndent />
								</div>
								<div className={clsx('col-span-4 md:col-start-2 md:col-span-5', 
									{ 'md:z-0 z-1': !first_image_on_top },
									{ 'md:z-1 z-3': first_image_on_top },
								)}>
									<C2ContentImageFigure first>
										<C2ContentImageElement		
											imageData={first_image}
										/>
									</C2ContentImageFigure>
								</div>
								<div className='col-span-5 col-start-2 md:col-start-auto'>
									<div className='parallax-slow'>
										<C2ContentImageFigure second>
											<C2ContentImageElement
												imageData={second_image}
											/>
											{second_image.title && (
												<C2ContentImageFigureCaption>
													{second_image.title}
												</C2ContentImageFigureCaption>
											)}
										</C2ContentImageFigure>
									</div>
								</div>
							</GridBlock>
						</GridContainer>
					</GridSpacing>
				</C2ContentImageBlock>
			</div>
		);
	}

	return (
		<div ref={rootRef}>
			<C2ContentImageBlock extraTopSpacing={extra_top_spacing}>
				<GridSpacing>
					<GridContainer>
						<GridBlock>
							{image_left
								?
								<>
									<div className='col-span-full md:col-start-2 md:col-span-5 lg:col-start-2 lg:col-span-4'>
										<C2ContentImageTitle>
											{title}
										</C2ContentImageTitle>
										<C2ContentImageDescription text={description} />
									</div>
									<div className='col-span-5 col-start-2 md:col-start-auto'>
										<div className='parallax-quick'>
											<C2ContentImageFigure>
												<C2ContentImageElement
													imageData={first_image}
												/>
												{first_image.title && (
													<C2ContentImageFigureCaption>
														{first_image.title}
													</C2ContentImageFigureCaption>
												)}
											</C2ContentImageFigure>
										</div>
									</div>
								</>
								:
								<>
									<div className='col-span-5 col-start-2 md:col-start-auto'>
										<div className='parallax-quick'>
											<C2ContentImageFigure>
												<C2ContentImageElement
													imageData={first_image}
												/>
												{first_image.title && (
													<C2ContentImageFigureCaption>
														{first_image.title}
													</C2ContentImageFigureCaption>
												)}
											</C2ContentImageFigure>
										</div>
									</div>
									<div className='col-span-full md:col-start-7 md:col-span-5 lg:col-start-7 lg:col-span-4'>
										<C2ContentImageTitle>
											{title}
										</C2ContentImageTitle>
										<C2ContentImageDescription text={description} />
									</div>
								</>
							}
						</GridBlock>
					</GridContainer>
				</GridSpacing>
			</C2ContentImageBlock>
		</div>
	);
};

export default C2ContentImageModule;
