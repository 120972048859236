import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import {
	C1MediaAnimationImageProps,
	C1MediaAnimationImageWrapperProps,
} from './types';

const C1MediaAnimationBlock = ({ children }: PropsWithChildren<unknown>) => (
	<div className="c1-media-animation">
		{children}
	</div>
);

const C1MediaAnimationImageWrapper = ({ children, id, expand, handleClick }: PropsWithChildren<C1MediaAnimationImageWrapperProps>) => (
	<div
		id={id}
		onClick={handleClick}
		className={clsx('c1-media-animation__image-wrapper', {
			'c1-media-animation__image-wrapper--expand': expand,
		})}
	>
		{children}
	</div>
);

const C1MediaAnimationImage = ({ imageData }: C1MediaAnimationImageProps) => {
	const { filename, alt } = imageData;

	return (
		<div className="c1-media-animation__image">
			{filename ? (
				<img src={filename + '/m/'} alt={alt} className="c1-media-animation__cover" />	
			) : null}
		</div>
	);
};

export {
	C1MediaAnimationBlock,
	C1MediaAnimationImage,
	C1MediaAnimationImageWrapper,
};
