import { Link } from 'gatsby';
import React from 'react';
import { C6ContentBlockLinkProps } from './types';

const C6ContentBlock = ({ children }) => <div className='c6-content-block'>{children}</div>;
const C6ContentBlockHeadline = ({ children }) => <h1 className='c6-content-block__headline'>{children}</h1>;
const C6ContentBlockText = ({ children }) => <p className='c6-content-block__text'>{children}</p>;
const C6ContentBlockLink = ({ linkItem }: C6ContentBlockLinkProps) => (
	linkItem.url ?
		<a className='button button--link button--black' href={linkItem.url.url} target="_blank" rel="noreferrer noopener">{linkItem.label}</a>
		:
		linkItem.url.cached_url &&
			<Link className='button button--link button--black' to={`/${linkItem.url.cached_url}`}>{linkItem.label}</Link>
);

export {
	C6ContentBlock,
	C6ContentBlockHeadline,
	C6ContentBlockText,
	C6ContentBlockLink,
};
