import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
	C8BigText,
	C8DetailImage,
	C8Designer,
	C8FullHeightMedia,
	C8HorizontalScrollWrapper,
	C8ReadMore,
	C8Section,
} from '.';

import { C8ModuleProps, C8ComponentsProps } from './types';
import { useWindowSize } from '../../hooks';

gsap.registerPlugin(ScrollTrigger);

const C8Components = {
	'c8-big-text': C8BigText,
	'c8-designer': C8Designer,
	'c8-detail-image': C8DetailImage,
	'c8-full-height-media': C8FullHeightMedia,
	'c8-read-more': C8ReadMore,
};

const C8HorizontalScrollModule = ({ blok: { body }, videos }: C8ModuleProps) => {
	const rootRef = useRef<HTMLDivElement>(null);
	const scrollContainer = useRef<HTMLDivElement>(null);
	const { width } = useWindowSize();

	let scrollTrigger: ScrollTrigger;

	const allImagesReady = async () => {
		const containers: HTMLImageElement[] = Array.from(rootRef.current.querySelectorAll('img'));
		const imageSources = containers.map(image => image.src);
		const allImagePromises = imageSources.map(source => {
			return new Promise(resolve => {
				const img = new Image();
				img.onload = () => resolve(true);
				img.onerror = () => resolve(false);
				img.src = source;
			});
		});
		const imagesReady = await detectImage(allImagePromises);
		if (imagesReady) {
			initAnimations();
		}
	};

	const detectImage = (imagePromises) => {
		if (imagePromises) {
			return Promise.allSettled(imagePromises).then(() => true);
		}
	};

	const initAnimations = () => {
		const scrollContainerWidth = scrollContainer.current.scrollWidth;
		const animation = gsap.to(scrollContainer.current, { x: -scrollContainerWidth + width, ease: 'none' });
		scrollTrigger = ScrollTrigger.create({
			trigger: rootRef.current,
			animation: animation,
			start: 'top top',
			end: 'bottom bottom',
			pin: scrollContainer.current,
			scrub: true,
		});
	};

	useEffect(() => {
		if (width) {
			allImagesReady();
			return () => scrollTrigger?.kill();
		}
	}, [width]);

	return (
		<div ref={rootRef} className="c8-horizontal-scroll">
			<C8Section>
				<C8HorizontalScrollWrapper>
					<div ref={scrollContainer} className='c8-horizontal-scroll__inner-wrapper'>
						{body.map((component) => {
							const Component: React.FC<C8ComponentsProps> = C8Components[component.component];
							if (typeof C8Components[component.component] !== 'undefined') {
								return <Component key={component._uid} {...component} videoAssets={videos} />;
							}
						})}
					</div>
				</C8HorizontalScrollWrapper>
			</C8Section>
		</div>
	);
};

export default C8HorizontalScrollModule;
