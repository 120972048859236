import React from 'react';
import { C9LinksModuleBlock, C9LinksModuleFigure, C9LinksModuleImage, C9LinksModuleImageLink, C9LinksModuleContainer, C9LinksModuleTitle, C9LinksModuleMobileScroller } from '.';
import { ButtonAnchorWrapper, InternalLinkButton } from '../button';
import { GridBlock, GridContainer, GridSpacing } from '../grid';

const C9LinksModule = ({ blok }) => {
	const { title, items } = blok;
	return (
		<C9LinksModuleBlock>
			<GridSpacing>
				<C9LinksModuleContainer>
					<GridContainer>
						<C9LinksModuleTitle>{title}</C9LinksModuleTitle>
						<div className='hidden md:block'>
							<GridBlock>
								{items && items.map(item =>
									<div key={item._uid} className="col-span-4">
										<C9LinksModuleImageLink link={item.link}>
											<C9LinksModuleFigure>
												<C9LinksModuleImage image={item.image} />
											</C9LinksModuleFigure>
										</C9LinksModuleImageLink>
										<ButtonAnchorWrapper black>
											<InternalLinkButton showCurtainAnimation to={`/${item.link.cached_url}`} black>
												{item.label}
											</InternalLinkButton>
										</ButtonAnchorWrapper>
									</div>,
								)}
							</GridBlock>
						</div>
					</GridContainer>
				</C9LinksModuleContainer>
			</GridSpacing>
			<div className='block md:hidden'>
				<C9LinksModuleMobileScroller>
					{items && items.map(item =>
						<div key={item._uid}>
							<C9LinksModuleImageLink link={item.link}>
								<C9LinksModuleFigure>
									<C9LinksModuleImage image={item.image}/>
								</C9LinksModuleFigure>
							</C9LinksModuleImageLink>
							<ButtonAnchorWrapper black>
								<InternalLinkButton to={`/${item.link.cached_url}`} black>
									{item.label}
								</InternalLinkButton>
							</ButtonAnchorWrapper>
						</div>,
					)}
				</C9LinksModuleMobileScroller>
			</div>
		</C9LinksModuleBlock>
	);
};

export default C9LinksModule;
