import React from 'react';
import { 
	C10SpecsBlock,
	C10SpecsItem,
	C10SpecsDownload,
	C10SpecsDownloadLink,
	C10SpecsImage,
	C10SpecsTitle,
} from './';
import {
	GridContainer,
	GridBlock,
} from '../grid';

const C10SpecsModule = ({ blok }) => {
	const {
		materials_label,
		dimensions_label,
		resources_label,
		materials,
		front_view,
		side_view,
		width,
		height,
		seat_height,
		depth,
		links,
	} = blok;

	return (
		<div className='bg-white py-100'>
			<GridContainer>
				<C10SpecsBlock>
					<GridBlock>
						<div className='col-span-4 col-start-2 md:col-start-1'>
							<C10SpecsImage imageData={front_view} />
						</div>
						<div className='col-span-4 col-start-2 md:col-start-5'>
							<C10SpecsImage imageData={side_view} />
						</div>
						<div className='flex flex-col justify-center col-span-4 col-start-2 align-middle pr-50 md:col-start-10 md:col-span-3'>
							<C10SpecsItem>
								<C10SpecsTitle>
									{dimensions_label}
								</C10SpecsTitle>
								<p>H: <span>{height}</span> — <span>SH: {seat_height}</span></p>
								<p>W: <span>{width}</span> — <span>D: {depth}</span></p>
							</C10SpecsItem>
							<C10SpecsItem>
								<C10SpecsTitle>
									{materials_label}
								</C10SpecsTitle>
								{materials}
							</C10SpecsItem>
							{links?.length > 0 && (
								<C10SpecsItem>
									<C10SpecsTitle>{resources_label}</C10SpecsTitle>
									<C10SpecsDownload>
										{links.map((link: C10SpecsLinkProps) => (
											<C10SpecsDownloadLink key={link._uid} to={link.url.cached_url}>
												{link.label}
											</C10SpecsDownloadLink>
										))}
									</C10SpecsDownload>
								</C10SpecsItem>
							)}
						</div>
					</GridBlock>
				</C10SpecsBlock>
			</GridContainer>
		</div>
	);
};

export default C10SpecsModule;
