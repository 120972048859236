import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import {
	D1ConfiguratorBlockProps,
	D1ConfiguratorImageProps,
	D1ConfiguratorOptionButtonProps,
	D1ConfiguratorDescriptionProps,
	D1ConfiguratorToggleArrowProps,
	D1ConfiguratorDescriptionWrapperProps,
	D1ConfiguratorCylindoWrapperProps,
	D1ConfiguratorProductBlokProps,
	D1ConfiguratorListProps,
} from './types';

const D1ConfiguratorBlock = ({ children, id }: PropsWithChildren<D1ConfiguratorBlockProps>) => (
	<div className="d1-configurator" id={id}>
		{children}
	</div>
);

const D1ConfiguratorProductListContainer = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__list-container">
		{children}
	</div>
);

const D1ConfiguratorProductWrapper = ({ children, isActive }: PropsWithChildren<D1ConfiguratorProductBlokProps>) => (
	<div className={clsx('d1-configurator__product-wrapper', { 'd1-configurator__product-wrapper--active': isActive })}>
		{children}
	</div>
);

const D1ConfiguratorOptionsContainer = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__options-container">
		{children}
	</div>
);

const D1ConfiguratorMediaContainer = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__media-container">
		{children}
	</div>
);

const D1ConfiguratorOptionsContent = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__options-content">
		{children}
	</div>
);

const D1ConfiguratorLabel = ({ children }: PropsWithChildren<unknown>) => (
	<p className="d1-configurator__label">
		{children}
	</p>
);

const D1ConfiguratorTitle = ({ children }: PropsWithChildren<unknown>) => (
	<h1 className="d1-configurator__title">
		{children}
	</h1>
);
const D1ConfiguratorTitleGroup = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__title-group">
		{children}
	</div>
);

const D1ConfiguratorPrice = ({ children }: PropsWithChildren<unknown>) => (
	<p className="d1-configurator__price">
		{children}
	</p>
);

const D1ConfiguratorImage = ({ src, alt, icon }: D1ConfiguratorImageProps) => (
	<img
		src={src}
		alt={alt}
		className={clsx('d1-configurator__image', {
			'd1-configurator__image--icon': icon,
		})}
	/>
);

const D1ConfiguratorOptionImage = ({ src, alt }: D1ConfiguratorImageProps) => (
	<img src={src} alt={alt} className="d1-configurator__option-image" />
);

const D1ConfiguratorList = ({ children, extraPaddingBottom }: PropsWithChildren<D1ConfiguratorListProps>) => (
	<ul className={clsx('d1-configurator__list', {['d1-configurator__list--padding-bottom']: extraPaddingBottom})}>
		{children}
	</ul>
);

const D1ConfiguratorListItem = ({ children }: PropsWithChildren<unknown>) => (
	<li className="d1-configurator__list-item">
		{children}
	</li>
);

const D1ConfiguratorOptionButton = ({ children, onClick, selected, title }: PropsWithChildren<D1ConfiguratorOptionButtonProps>) => (
	<button
		onClick={onClick}
		title={title}
		className={clsx('d1-configurator__option-button', {
			'd1-configurator__option-button--selected': selected,
		})}
	>
		{children}
	</button>
);

const D1ConfiguratorOptionTextButton = ({ children, onClick, selected }: PropsWithChildren<D1ConfiguratorOptionButtonProps>) => (
	<button
		onClick={onClick}
		className={clsx('d1-configurator__option-text', {
			'd1-configurator__option-text--selected': selected,
		})}
	>
		{children}
	</button>
);

const D1ConfiguratorActionsWrapper = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__actions-wrapper">
		{children}
	</div>
);

const D1ConfiguratorOptionTitle = ({ children }: PropsWithChildren<unknown>) => (
	<span className="d1-configurator__option-title">
		{children}
	</span>
);

const D1ConfiguratorDescription = ({ html }: D1ConfiguratorDescriptionProps) => (
	<div dangerouslySetInnerHTML={{ __html: html }} className="d1-configurator__description" />
);

const D1ConfiguratorToggleArrow = ({ children, pointUp }: PropsWithChildren<D1ConfiguratorToggleArrowProps>) => (
	<div className={clsx('d1-configurator__toggle-arrow', {
		'd1-configurator__toggle-arrow--point-up': pointUp,
	})}>
		{children}
	</div>
);

const D1ConfiguratorToggleLabel = ({ children }: PropsWithChildren<unknown>) => (
	<span className="d1-configurator__toggle-label">
		{children}
	</span>
);

const D1ConfiguratorDescriptionWrapper = ({ children, style }: PropsWithChildren<D1ConfiguratorDescriptionWrapperProps>) => (
	<div className="d1-configurator__description-wrapper" style={style}>
		{children}
	</div>
);

const D1ConfiguratorCylindoViewer = ({ pid }: {pid: string}) => (
	<div id={`cylindo-product-viewer-${pid}`} className="d1-configurator__cylindo-viewer" />
);

const D1ConfiguratorCylindoWrapper = ({ children, hide }: PropsWithChildren<D1ConfiguratorCylindoWrapperProps>) => (
	<div className={clsx('d1-configurator__cylindo-wrapper', {
		'd1-configurator__cylindo-wrapper--hide': hide,
	})}>
		{children}
	</div>
);

const D1ConfiguratorTooltipsWrapper = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__tooltips-wrapper">
		{children}
	</div>
);

const D1ConfiguratorTooltipItem = ({ children }: PropsWithChildren<unknown>) => (
	<div className="d1-configurator__tooltip-item">
		{children}
	</div>
);

const D1ConfiguratorTooltipLabel = ({ children }: PropsWithChildren<unknown>) => (
	<p className="d1-configurator__tooltip-label">
		{children}
	</p>
);

export {
	D1ConfiguratorBlock,
	D1ConfiguratorOptionsContainer,
	D1ConfiguratorMediaContainer,
	D1ConfiguratorLabel,
	D1ConfiguratorTitle,
	D1ConfiguratorPrice,
	D1ConfiguratorImage,
	D1ConfiguratorList,
	D1ConfiguratorListItem,
	D1ConfiguratorOptionButton,
	D1ConfiguratorOptionTextButton,
	D1ConfiguratorActionsWrapper,
	D1ConfiguratorOptionsContent,
	D1ConfiguratorOptionTitle,
	D1ConfiguratorOptionImage,
	D1ConfiguratorDescription,
	D1ConfiguratorToggleArrow,
	D1ConfiguratorToggleLabel,
	D1ConfiguratorDescriptionWrapper,
	D1ConfiguratorTitleGroup,
	D1ConfiguratorCylindoViewer,
	D1ConfiguratorCylindoWrapper,
	D1ConfiguratorTooltipsWrapper,
	D1ConfiguratorTooltipItem,
	D1ConfiguratorTooltipLabel,
	D1ConfiguratorProductListContainer,
	D1ConfiguratorProductWrapper,
};
