import React from 'react';
import { A2FooterBlock, A2FooterColumn, A2FooterLink, A2FooterLinkContainer, A2FooterTitle } from './index';
import { GridSpacing, GridContainer, GridBlock } from '../grid';

const A2FooterModule = ({ content }) => {
	return (
		<A2FooterBlock>
			<GridSpacing>
				<GridContainer>
					<GridBlock>
						<div className='col-span-6 md:col-span-2'>
							<A2FooterColumn>
								{content.column_title_one && 
									<A2FooterTitle>
										{content.column_title_one}
									</A2FooterTitle>}
								<A2FooterLinkContainer>
									{content.links_one && content.links_one.map(linkItem =>
										<A2FooterLink key={linkItem._uid} linkItem={linkItem} />,
									)}
								</A2FooterLinkContainer>
							</A2FooterColumn>
						</div>
						<div className='col-span-6 md:col-span-2'>
							<A2FooterColumn>
								{content.column_title_two &&
									<A2FooterTitle>
										{content.column_title_two}
									</A2FooterTitle>}
								<A2FooterLinkContainer>
									{content.links_two && content.links_two.map(linkItem =>
										<A2FooterLink key={linkItem._uid} linkItem={linkItem} />,
									)}
								</A2FooterLinkContainer>
							</A2FooterColumn>
						</div>
						<div className='col-span-6 md:col-span-2'>
							<A2FooterColumn>
								{content.column_title_three && 
									<A2FooterTitle>
										{content.column_title_three}
									</A2FooterTitle>}
								<A2FooterLinkContainer>
									{content.links_three && content.links_three.map(linkItem =>
										<A2FooterLink key={linkItem._uid} linkItem={linkItem} />,
									)}
								</A2FooterLinkContainer>
							</A2FooterColumn>
						</div>
						<div className='col-span-6 md:col-span-2'>
							<A2FooterColumn>
								{content.column_title_four &&
									<A2FooterTitle>
										{content.column_title_four}
									</A2FooterTitle>}
								<A2FooterLinkContainer>
									{content.links_four && content.links_four.map(linkItem =>
										<A2FooterLink key={linkItem._uid} linkItem={linkItem} />,
									)}
								</A2FooterLinkContainer>
							</A2FooterColumn>
						</div>
						<div className='col-span-6 md:col-span-4'>
							<A2FooterColumn last>
								{content.column_title_five &&
									<A2FooterTitle>
										{content.column_title_five}
									</A2FooterTitle>}
								<A2FooterLinkContainer>
									{content.links_five && content.links_five.map(linkItem =>
										<A2FooterLink key={linkItem._uid} linkItem={linkItem} styleLikeButton />,
									)}
								</A2FooterLinkContainer>
							</A2FooterColumn>
						</div>
					</GridBlock>
				</GridContainer>
			</GridSpacing>
		</A2FooterBlock>
	);
};

export default A2FooterModule;
