import React, { PropsWithChildren } from 'react';
import {
	C5ContentBlock,
	C5ContentBlockContent,
	C5ContentblockPreTitle,
	C5ContentBlockTitle,
} from '.';
import {
	GridContainer,
	GridBlock,
	GridSpacing,
} from '../grid';
import { C5ContentBlockModuleProps } from './types';

const C5ContentBlockModule = ({ blok: { background_color, content, pretitle, title } }: PropsWithChildren<C5ContentBlockModuleProps>) => (
	<C5ContentBlock backgroundColor={background_color}>
		<GridSpacing>
			<GridContainer>
				<GridBlock>
					<div className='col-span-5 col-start-1 md:col-span-7 md:col-start-3'>
						{pretitle && (
							<C5ContentblockPreTitle>
								{pretitle}
							</C5ContentblockPreTitle>
						)}
						{title && (
							<C5ContentBlockTitle>
								{title}
							</C5ContentBlockTitle>
						)}
						{content && (
							<C5ContentBlockContent content={content} />
						)}
					</div>
				</GridBlock>
			</GridContainer>
		</GridSpacing>
	</C5ContentBlock>
);

export default C5ContentBlockModule;
