import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Meta from '../../components/meta';
import Layout from '../../components/layout';
import DynamicComponent from '../../components/blocks-container';
import { PenguinDataProps, ContentBodyProps } from './types';
import A2FooterModule from '../../components/a2-footer/module';
import { useFileSource } from '../../hooks';

gsap.registerPlugin(ScrollTrigger);

const Components = ({ body, fullscreenImages, constrainedImages, videos }: ContentBodyProps) => {
	return (
		<>
			{body.map(blok => (
				<DynamicComponent
					key={blok._uid}
					blok={blok}
					fullscreenImages={fullscreenImages}
					constrainedImages={constrainedImages}
					videos={videos}
				/>
			))}
		</>
	);
};

const PenguinTemplate: React.FC<PenguinDataProps> = ({ pageContext }) => {
	const { content, fullscreenImages, constrainedImages, videos, slug, footer } = pageContext;
	const {
		body,
		meta_title,
		meta_description,
		meta_image,
	} = content;
	const rootRef = useRef();
	const metaImage = useFileSource(fullscreenImages, meta_image?.filename);

	const createPinningAnimation = () => {
		const root = gsap.utils.selector(rootRef);

		ScrollTrigger.create({
			trigger: root('.b1-product-hero'),
			start: 'top top',
			pin: true, 
			pinSpacing: false,
		});
	};

	useEffect(() => {
		document.body.removeAttribute('style');
		createPinningAnimation();
	}, []);

	return (
		<Layout path={slug}>
			<Meta
				language="en"
				title={meta_title}
				description={meta_description}
				ogImage={metaImage?.node?.publicURL || meta_image?.filename}
				hrefLangEn={`https://eu.iconsbymenu.com/${slug}`}
				hrefLangEnUs={`https://us.iconsbymenu.com/${slug}`}
				hrefLangUrlXDefault={`https://eu.iconsbymenu.com/${slug}`}
			/>
			<div ref={rootRef}>
				<Components
					body={body}
					fullscreenImages={fullscreenImages}
					constrainedImages={constrainedImages}
					videos={videos}
				/>
			</div>
			<A2FooterModule content={footer}/>
		</Layout>
	);
};

export default PenguinTemplate;
