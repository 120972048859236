import React, { PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';
import { richTextResolver } from '../../utils';
import {
	C2ContentImageBlockProps,
	C2ContentImageElementProps,
	C2ContentImageDescriptionProps,
	C2ContentImageFigureProps,
} from './types';
import RichText from '../../components/richtext';

const C2ContentImageBlock = ({ children, mobileNoTopSpace, extraTopSpacing, doubleImages }: PropsWithChildren<C2ContentImageBlockProps>) => (
	<div className={clsx('c2-content-image', {
		'c2-content-image--no-topspace': mobileNoTopSpace,
		'c2-content-image--extra-topspace': extraTopSpacing,
		'c2-content-image--double-images': doubleImages,
	})}>
		{children}
	</div>
);

const C2ContentImageWrapper = ({ children }: PropsWithChildren<unknown>) => (
	<div className="c2-content-image__wrapper">
		{children}
	</div>
);

const C2ContentImageTitle = ({ children }: PropsWithChildren<unknown>) => (
	<h1 className="c2-content-image__title">
		{children}
	</h1>
);

const C2ContentImageFigure = ({ children, first, second }: PropsWithChildren<C2ContentImageFigureProps>) => (
	<figure className={clsx('c2-content-image__figure', {
		'c2-content-image__figure--first': first,
		'c2-content-image__figure--second': second,
	})}>
		{children}
	</figure>
);

const C2ContentImageElement = ({ imageData }: C2ContentImageElementProps) => {
	const { alt, filename } = imageData;

	return (
		<>
			{filename ? (
				<img src={filename + '/m/'} alt={alt} className="c2-content-image__alt-image" />
			) : null}
		</>
	);
};

const C2ContentImageFigureCaption = ({ children }: PropsWithChildren<unknown>) => (
	<figcaption className="c2-content-image__caption">
		{children}
	</figcaption>
);

const C2ContentImageDescription = ({ text, rightIndent }: C2ContentImageDescriptionProps) => {
	const html = useMemo(() => richTextResolver(text), [text]);

	return (
		<RichText html={html} className={clsx('c2-content-image__description', {
			'c2-content-image__description--right-indent': rightIndent,
		})} />
	);
};

export {
	C2ContentImageBlock,
	C2ContentImageWrapper,
	C2ContentImageTitle,
	C2ContentImageFigure,
	C2ContentImageElement,
	C2ContentImageFigureCaption,
	C2ContentImageDescription,
};
