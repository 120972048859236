import React from 'react';
import { C11QuoteBlock, C11QuoteFigCaption, C11QuoteFigure, C11QuoteText } from '.';
import { GridBlock, GridContainer, GridSpacing } from '../grid';

const C11QuoteModule = ({ blok }) => {
	const {
		quote,
		from,
		no_padding_bottom,
	} = blok;

	return (
		<C11QuoteBlock noPaddingBottom={no_padding_bottom}>
			<GridSpacing>
				<GridContainer>
					<GridBlock>
						<div className='col-span-6 md:col-start-4 md:col-span-8'>
							<C11QuoteFigure>
								<C11QuoteText>{quote}</C11QuoteText>
								<C11QuoteFigCaption>{from}</C11QuoteFigCaption>
							</C11QuoteFigure>
						</div>
					</GridBlock>
				</GridContainer>
			</GridSpacing>
		</C11QuoteBlock>
	);
};

export default C11QuoteModule;
