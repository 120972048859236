import gql from 'graphql-tag';

const GET_PRODUCT = gql`
	query GetProduct($id: ID!) {
		product(id: $id) {
			id
			title
			descriptionHtml
			metafieldProductCode: metafield(key: "productcode", namespace: "cylindo") {
				value
			}
			variants(first: 100) {
				edges {
					node {
						id
						title
						priceV2 {
							amount
							currencyCode
						}
						image {
							url
							altText
						}
						selectedOptions {
							name
							value
						}
						metafieldCylindo: metafield(key: "features", namespace: "cylindo") {
							value
						}
						metafieldDelivery: metafield(key: "leadtimeweeks", namespace: "availability") {
							value
						}
					}
				}
			}
		}
	}
`;

export {
	GET_PRODUCT,
};
