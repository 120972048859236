import React, { PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';
import { richTextResolver } from '../../utils';
import {
	C4MediaFigureProps,
	C4MediaElementProps,
	C4MediaDescriptionProps,
	C4MediaBlockProps,
	C4MediaTextAreaProps,
} from './types';
import RichText from '../richtext';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const C4MediaBlock = ({ children, hasCaption, defaultSpacing }: PropsWithChildren<C4MediaBlockProps>) => (
	<div className={clsx('c4-media', {
		'c4-media--caption': hasCaption,
		'c4-media--default-spacing': defaultSpacing,
	})}>
		{children}
	</div>
);

const C4MediaWrapper = ({ children }: PropsWithChildren<unknown>) => (
	<div className="c4-media__wrapper">
		{children}
	</div>
);

const C4MediaTitle = ({ children }: PropsWithChildren<unknown>) => (
	<h1 className="c4-media__title">
		{children}
	</h1>
);

const C4MediaFigure = ({ children, first, second, noTopSpace }: PropsWithChildren<C4MediaFigureProps>) => (
	<figure className={clsx('c4-media__figure', {
		'c4-media__figure--first': first,
		'c4-media__figure--second': second,
		'c4-media__figure--no-topspace': noTopSpace,
	})}>
		{children}
	</figure>
);

const C4MediaElement = ({ imageData }: C4MediaElementProps) => {
	const {filename, alt} = imageData;

	return (
		<>
			{filename ? (
				<img src={filename + '/m/'} alt={alt} className="c4-media__alt-image" />
			) : null}
		</>
	);
};

const C4MediaFigureCaption = ({ children }: PropsWithChildren<unknown>) => (
	<figcaption className="c4-media__caption">
		{children}
	</figcaption>
);

const C4MediaDescription = ({ text }: C4MediaDescriptionProps) => {
	const html = useMemo(() => richTextResolver(text), [text]);

	return (
		<RichText html={html} className="c4-media__description" />
	);
};

const C4MediaTextArea = ({ children, hideMobile }: PropsWithChildren<C4MediaTextAreaProps>) => (
	<div className={clsx('c4-media__text-area', {
		'c4-media__text-area--hide-mobile': hideMobile,
	})}>
		{children}
	</div>
);

export {
	C4MediaBlock,
	C4MediaWrapper,
	C4MediaTitle,
	C4MediaFigure,
	C4MediaElement,
	C4MediaFigureCaption,
	C4MediaDescription,
	C4MediaTextArea,
};
