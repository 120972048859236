import React, { useEffect, useRef } from 'react';
import { C12MediaAnimationBlock, C12MediaAnimationImage } from '.';
import { GridBlock, GridContainer, GridSpacing } from '../grid';
import { C12MediaAnimationProps } from './types';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useWindowSize } from '../../hooks';

gsap.registerPlugin(ScrollTrigger);

const C12MediaAnimationModule = ({ blok }: C12MediaAnimationProps) => {

	const { main_image, sub_images } = blok;
	const { height, width } = useWindowSize();
	const animationContainerRef = useRef<HTMLDivElement>();
	const containerRef = useRef<HTMLDivElement>();
	const mainImageRef = useRef<HTMLDivElement>();
	const subImagesRef = useRef([]);
	const offset = 30;
	const subImageWidth = 423; // TODO: add dynamic width from each subImage
	const mdBreakPoint = 768; // TODO: should be added from tailwind

	useEffect(() => {
		const isBelowMediumWidth = width < mdBreakPoint;
		const animationTimeline = gsap.timeline();
		isBelowMediumWidth ? gsap.set(mainImageRef.current, { zIndex: 0 }) : gsap.set(mainImageRef.current, { zIndex: sub_images.length });
		subImagesRef.current.forEach((subImage, i) => {
			const isEven = i % 2 === 0;
			if (isBelowMediumWidth) {
				gsap.set(subImage, {
					xPercent: isEven ? -150 : 150,
					zIndex: i + 1,
				});
			} else {
				gsap.set(subImage, {
					xPercent: 0,
					zIndex: i,
				});
			}
			animationTimeline.to(subImage, {
				x: () => isBelowMediumWidth ? 0 : subImageWidth + offset,
				xPercent: 0,
			});
		});
		const animationScrollTrigger = ScrollTrigger.create({
			trigger: containerRef.current,
			animation: animationTimeline,
			pin: animationContainerRef.current,
			start: () => `top ${height / 2 - mainImageRef.current.clientHeight / 2}px`,
			end: () => `bottom ${height / 2 + mainImageRef.current.clientHeight / 2}px`,
			scrub: true,
		});

		return () => {
			animationScrollTrigger.kill();
		};
	}, [width]);

	return (
		<div ref={containerRef}>
			<C12MediaAnimationBlock height={`${sub_images.length * 100}vh`} >
				<GridSpacing>
					<GridContainer>
						<GridBlock>
							<div ref={animationContainerRef} className='relative w-full col-span-4 col-start-2 md:col-end-10'>
								<figure ref={mainImageRef} className='c12-media-animation__panel'>
									<C12MediaAnimationImage filename={main_image?.filename} alt={main_image?.alt} />
								</figure>
								{sub_images && sub_images.map((image, i) =>
									<figure className='c12-media-animation__panel' key={image.id} ref={el => subImagesRef.current[i] = el} >
										<C12MediaAnimationImage filename={image?.filename} alt={image?.alt} />
									</figure>,
								)}
							</div>
						</GridBlock>
					</GridContainer>
				</GridSpacing>
			</C12MediaAnimationBlock>
		</div>
	);
};

export default C12MediaAnimationModule;
