import React, { PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';
import { C5ContentBlockProps, C5RichtextConentProps } from './types';
import RichText from '../../components/richtext';
import { richTextResolver } from '../../utils';

const C5ContentBlock = ({ children, backgroundColor }: PropsWithChildren<C5ContentBlockProps>) => (
	<section className={clsx('c5-content-block', {
		'c5-content-block--light': backgroundColor === 'light',
		'c5-content-block--dark': backgroundColor === 'dark',
	})}>
		{children}
	</section>
);

const C5ContentBlockTitle = ({ children }) => (<h2 className='c5-content-block__title'>{children}</h2>);

const C5ContentBlockContent = ({ content }: C5RichtextConentProps) => {
	const html = useMemo(() => richTextResolver(content), [content]);

	return (
		<RichText html={html} className='c5-content-block__content'/>
	);};

const C5ContentblockPreTitle = ({ children }) => (<span className='c5-content-block__pretitle'>{children}</span>);

export { C5ContentBlock, C5ContentBlockContent, C5ContentblockPreTitle, C5ContentBlockTitle };
