import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { C4MediaModuleProps } from './types';
import {
	C4MediaBlock,
	C4MediaFigure,
	C4MediaElement,
	C4MediaFigureCaption,
	C4MediaTextArea,
} from './index';
import {
	GridSpacing,
	GridContainer,
	GridBlock,
} from '../grid';

gsap.registerPlugin(ScrollTrigger);

const C4MediaModule = ({ blok }: C4MediaModuleProps) => {
	const {
		first_image,
		second_image,
		design_variant,
		second_image_description,
		caption,
	} = blok;
	const rootRef = useRef();

	const parallaxAnimation = (elements, posY) => {
		return gsap.fromTo(elements, {
			y: posY,
		}, {
			y: -posY,
			ease: 'Power0.easeNone',
		});
	};

	const createParallaxEffect = () => {
		const root = gsap.utils.selector(rootRef);
		const slowElements = root('.parallax-slow');
		const quickElements = root('.parallax-quick');

		if (slowElements.length > 0) {
			ScrollTrigger.create({
				trigger: slowElements,
				animation: parallaxAnimation(slowElements, 50),
				start: 'top bottom',
				end: 'bottom 200px',
				scrub: true,
			});
		}

		if (quickElements.length > 0) {
			ScrollTrigger.create({
				trigger: quickElements,
				animation: parallaxAnimation(quickElements, 150),
				start: 'top bottom',
				end: 'bottom 200px',
				scrub: true,
			});
		}
	};

	useEffect(() => {
		createParallaxEffect();
	}, []);

	if (design_variant === '3') {
		return (
			<div ref={rootRef}>
				<C4MediaBlock defaultSpacing>
					<GridSpacing>
						<GridContainer>
							<GridBlock>
								<div className="col-span-6 md:col-start-2 md:flex md:items-center">
									<div className='parallax-slow'>
										<C4MediaFigure noTopSpace>
											<C4MediaElement
												imageData={first_image}
											/>
										</C4MediaFigure>
									</div>
								</div>
								<div className="col-span-4 col-start-2 md:flex md:items-center md:col-start-9 md:col-span-3">
									<div className='parallax-quick'>
										<C4MediaFigure>
											<C4MediaElement
												imageData={second_image}
											/>
											{caption && (
												<C4MediaFigureCaption>
													{caption}
												</C4MediaFigureCaption>
											)}
										</C4MediaFigure>
									</div>
								</div>
							</GridBlock>
						</GridContainer>
					</GridSpacing>
				</C4MediaBlock>
			</div>
		);
	}

	if (design_variant === '2') {
		return (
			<div ref={rootRef}>
				<C4MediaBlock defaultSpacing>
					<GridSpacing>
						<GridContainer>
							<GridBlock>
								<div className="col-span-4 col-start-3 lg:pt-100">
									<div className="parallax-quick">
										<C4MediaFigure noTopSpace>
											<C4MediaElement
												imageData={first_image}
											/>
										</C4MediaFigure>
									</div>
								</div>
								<div className="col-span-4 md:col-start-8 md:col-span-3">
									<C4MediaFigure>
										<C4MediaElement
											imageData={second_image}
										/>
									</C4MediaFigure>
									<C4MediaTextArea hideMobile>
										{second_image_description}
									</C4MediaTextArea>
								</div>
								{second_image_description && (
									<div className="block col-span-full md:col-start-8 md:col-span-3 md:hidden">
										<C4MediaTextArea>
											{second_image_description}
										</C4MediaTextArea>
									</div>
								)}
							</GridBlock>
						</GridContainer>
					</GridSpacing>
				</C4MediaBlock>
			</div>
		);
	}

	return (
		<div ref={rootRef}>
			<C4MediaBlock hasCaption={caption}>
				<GridSpacing>
					<GridContainer>
						<GridBlock>
							<div className="flex items-end justify-end col-span-4 md:col-start-2 z-1 md:col-span-5">
								<div className="parallax-quick">
									<C4MediaFigure first>
										<C4MediaElement
											imageData={first_image}
										/>
									</C4MediaFigure>
								</div>
							</div>
							<div className="z-0 flex items-end justify-start col-span-6 col-start-2 md:col-start-auto">
								<div className="parallax-slow">
									<C4MediaFigure second>
										<C4MediaElement
											imageData={second_image}
										/>
										{caption && (
											<C4MediaFigureCaption>
												{caption}
											</C4MediaFigureCaption>
										)}
									</C4MediaFigure>
								</div>
							</div>
						</GridBlock>
					</GridContainer>
				</GridSpacing>
			</C4MediaBlock>
		</div>
	);
};

export default C4MediaModule;
