import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { C1MediaAnimationModuleProps } from './types';
import {
	C1MediaAnimationBlock,
	C1MediaAnimationImage,
	C1MediaAnimationImageWrapper,
} from './index';
import { useMediaMatchesResize } from '../../hooks';

gsap.registerPlugin(ScrollTrigger);

const C1MediaAnimationModule = ({ blok }: C1MediaAnimationModuleProps) => {
	const {
		first_image,
		second_image,
	} = blok;
	const rootRef = useRef<HTMLDivElement>();
	const [expandFirstImage, setExpandFirstImage] = useState(true);
	const [expandSecondImage, setExpandSecondImage] = useState(false);
	const isMobile = useMediaMatchesResize('(max-width: 979px)');

	const handleOnEnter = () => {
		setExpandFirstImage(prev => !prev);
		setExpandSecondImage(prev => !prev);
	};

	const handleOnLeave = () => {
		setExpandFirstImage(prev => !prev);
		setExpandSecondImage(prev => !prev);
	};

	const createImageAnimation = () => {
		ScrollTrigger.matchMedia({
			'(min-width: 980px)': () => {
				ScrollTrigger.create({
					trigger: rootRef.current,
					start: 'top 20%',
					end: 'bottom top',
					onEnter: () => handleOnEnter(),
					onLeaveBack: () => handleOnLeave(),
				});
			},
			'(max-width: 979px)': () => {
				ScrollTrigger.create({
					trigger: rootRef.current,
					start: '75% bottom',
					end: 'bottom bottom',
					onEnter: () => handleOnEnter(),
					onLeaveBack: () => handleOnLeave(),
				});
			},
		});
	};

	useEffect(() => {
		setExpandFirstImage(isMobile);
		setExpandSecondImage(!isMobile);
		createImageAnimation();
	}, []);

	const toggleExpanded = () => {
		setExpandFirstImage(prev => !prev);
		setExpandSecondImage(prev => !prev);
	};

	return (
		<div ref={rootRef} className="relative">
			<C1MediaAnimationBlock>
				<C1MediaAnimationImageWrapper handleClick={toggleExpanded} expand={expandFirstImage}>
					<C1MediaAnimationImage imageData={first_image} />
				</C1MediaAnimationImageWrapper>
				<C1MediaAnimationImageWrapper handleClick={toggleExpanded} expand={expandSecondImage}>
					<C1MediaAnimationImage imageData={second_image} />
				</C1MediaAnimationImageWrapper>
			</C1MediaAnimationBlock>
		</div>
	);
};

export default C1MediaAnimationModule;
