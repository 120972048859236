import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import kebabCase from 'lodash/kebabCase';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import { GET_PRODUCT } from '../../apollo/queries';
import { showSideBasketVar, showStoreLocatorVar } from '../../apollo/cache';
import { useAddItemToCart } from '../../shopify/hooks';
import {
	GridBlock,
	GridContainer,
} from '../../components/grid';
import {
	ButtonElement,
	ButtonBackArrow,
} from '../button';
import { ThrobberBlock } from '../throbber';
import {
	D1ConfiguratorBlock,
	D1ConfiguratorMediaContainer,
	D1ConfiguratorOptionsContainer,
	D1ConfiguratorLabel,
	D1ConfiguratorTitle,
	D1ConfiguratorPrice,
	D1ConfiguratorImage,
	D1ConfiguratorList,
	D1ConfiguratorListItem,
	D1ConfiguratorOptionButton,
	D1ConfiguratorOptionTextButton,
	D1ConfiguratorProductListContainer,
	D1ConfiguratorProductWrapper,
	D1ConfiguratorActionsWrapper,
	D1ConfiguratorOptionsContent,
	D1ConfiguratorOptionTitle,
	D1ConfiguratorOptionImage,
	D1ConfiguratorDescription,
	D1ConfiguratorToggleArrow,
	D1ConfiguratorToggleLabel,
	D1ConfiguratorDescriptionWrapper,
	D1ConfiguratorTitleGroup,
	D1ConfiguratorCylindoViewer,
	D1ConfiguratorCylindoWrapper,
	D1ConfiguratorTooltipsWrapper,
	D1ConfiguratorTooltipItem,
	D1ConfiguratorTooltipLabel,
} from './index';
import {
	SelectedOptionsProps,
	ColorOptionsByMaterialProps,
} from './types';
import dragIcon from '../../images/MENU_drag.svg';
import zoomIcon from '../../images/MENU_zoom.svg';

const D1ConfiguratorProduct = ({
	add_to_cart_label,
	delivery_label,
	delivery_time_unit,
	no_delivery_time,
	readmore_label,
	readless_label,
	seat_label,
	shell_label,
	subtitle,
	polster_images,
	no_product_image_label,
	locate_in_store,
	drag_label,
	zoom_label,
	product,
	isActive,
	activeProduct,
	handleChangeProduct,
	products,
	languageCode,
	alternative_products_title,
}) => {

	const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
	const [isAddingItemToCart, setIsAddingItemToCart] = useState(false);
	const [showViewerFallback, setShowViewerFallback] = useState(false);
	const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(0);
	const [selectedVariantId, setSelectedVariantId] = useState('');
	const addItemToCart = useAddItemToCart();

	const { data, loading, error } = useQuery(GET_PRODUCT, {
		variables: {
			id: `gid://shopify/Product/${product.Id}`,
		},
	});

	const viewerInstance = useRef<any>(null);
	const descriptionRef = useRef<HTMLDivElement>(null);
	const getDescriptionHeight = useMemo(() => {
		if (isDescriptionExpanded && descriptionRef.current) {
			return descriptionRef.current.clientHeight;
		}
		return '50px';
	}, [isDescriptionExpanded]);

	const addToCart = async (variantId: string) => {
		const quantity = 1;
		setIsAddingItemToCart(true);

		try {
			await addItemToCart(variantId, quantity);
			setIsAddingItemToCart(false);
			showSideBasketVar(true);
		} catch {
			console.error(`There was a problem adding item with ID ${variantId} to the cart.`);
			setIsAddingItemToCart(false);
		}
	};

	const createCylindoViewer = () => {
		if (!metafieldProductCode || !window.cylindo) {
			return setShowViewerFallback(true);
		}

		const options = {
			'accountID': 5084,
			'productCode': metafieldProductCode.value,
			'features': getCylindoVariantFeatures(),
			'containerID': `#cylindo-product-viewer-${product.Id}`,
			'thumbs' : false,
			'fullscreen': false,
			'zoomButton':	false,
			'tooltipDragText': null,
			'tooltipZoomText': null,
		};

		// do not instantiate the viewer until the framework reports ready.
		window.cylindo.on('ready', () => {
			// create the instance
			viewerInstance.current = window.cylindo.viewer.create(options);
			viewerInstance.current.on(viewerInstance.current.events.ERROR, () => setShowViewerFallback(true));
			viewerInstance.current.on(viewerInstance.current.events.FEATURES_ERROR, () => setShowViewerFallback(true));
			viewerInstance.current.on(viewerInstance.current.events.THREESIXTY_COMPLETE, () => setShowViewerFallback(false));
		});
	};

	const getCylindoVariantFeatures = (): string[] => {
		if (data?.product.variants.edges.length > 0) {
			const variantItem = variants.edges.find(item => item.node.id === selectedVariantId);
			return variantItem?.node.metafieldCylindo ? JSON.parse(variantItem.node.metafieldCylindo.value) : [];
		}
		return [];
	};

	const updateCylindoVariantFeatures = () => {
		const features = getCylindoVariantFeatures();
		viewerInstance.current.setFeatures(features);
	};

	const getMaterialOptions = () => {
		if (data?.product.variants.edges.length > 0) {
			const selectedOptions = variants.edges.map(item => item.node.selectedOptions);
			const flatAllOptions: SelectedOptionsProps[] = flatten(selectedOptions);
			const uniqueOptions = uniqBy(flatAllOptions, 'value');
			return uniqueOptions.filter(item => item.name === 'Material');
		}
		return [];
	};

	const getColorOptionsByMaterial = (): ColorOptionsByMaterialProps[] => {
		if (data?.product.variants.edges.length > 0) {
			const selectedMaterialOption = getMaterialOptions()[selectedMaterialIndex].value;
			return variants.edges.filter(item => {
				return item.node.selectedOptions.find((option: SelectedOptionsProps) => {
					return option.name === 'Material' && option.value === selectedMaterialOption;
				});
			});

			return variants.edges.filter(item => {
				return item.node.selectedOptions.find((option: SelectedOptionsProps) => {
					return option.name === 'Material' && option.value === selectedMaterialOption;
				});
			});
		}
		return [];
	};

	const handleUpdateSelectedVariant = () => {
		if (data?.product.variants.edges.length > 0) {
			const colorVariants = getColorOptionsByMaterial();
			if (colorVariants.length > 0) {
				return setSelectedVariantId(colorVariants[0].node.id);
			}
			return setSelectedVariantId('');
		}
	};

	const getColorOptionImage = (filename: string, value: string) => {
		const imageSourceLastPathname = filename.substring(filename.lastIndexOf('/') + 1);
		const [name] = imageSourceLastPathname.split('.');
		return kebabCase(name) === kebabCase(value);
	};

	const changeProduct = (id: string) => {
		if (id !== product.Id) {
			setIsDescriptionExpanded(false);
			handleChangeProduct(id);
		}
	};

	useEffect(() => {
		if (selectedVariantId && viewerInstance.current === null) {
			createCylindoViewer();
		} else if (selectedVariantId && viewerInstance.current) {
			updateCylindoVariantFeatures();
		}
	}, [selectedVariantId]);

	useEffect(() => {
		if (data?.product) {
			handleUpdateSelectedVariant();
		}
	}, [data, selectedMaterialIndex, product]);

	if (loading) return <div className='flex items-center justify-center w-full h-screen'><ThrobberBlock /></div>;
	if (error || data?.product === null) return null;

	const {
		title,
		variants,
		descriptionHtml,
		metafieldProductCode,
	} = data.product;

	return (
		<D1ConfiguratorProductWrapper isActive={isActive}>
			<GridBlock>
				<div className="col-span-full lg:col-span-7">
					<D1ConfiguratorMediaContainer>
						{showViewerFallback && (
							variants.edges
								.filter(item => item.node.id === selectedVariantId)
								.map(item => (
									item.node.image ? (
										<D1ConfiguratorImage
											key={`image-${item.node.id}`}
											src={item.node.image.url}
											alt={item.node.image.altText}
										/>
									) : (
										<D1ConfiguratorLabel>
											{no_product_image_label}
										</D1ConfiguratorLabel>
									)
								))
						)}
						<D1ConfiguratorCylindoWrapper hide={showViewerFallback}>
							<D1ConfiguratorCylindoViewer pid={product.Id}/>
							<D1ConfiguratorTooltipsWrapper>
								{zoom_label && (
									<D1ConfiguratorTooltipItem>
										<D1ConfiguratorImage icon src={zoomIcon} alt="Tooltip" />
										<D1ConfiguratorTooltipLabel>
											{zoom_label}
										</D1ConfiguratorTooltipLabel>
									</D1ConfiguratorTooltipItem>
								)}
								{drag_label && (
									<D1ConfiguratorTooltipItem>
										<D1ConfiguratorImage icon src={dragIcon} alt="Tooltip" />
										<D1ConfiguratorTooltipLabel>
											{drag_label}
										</D1ConfiguratorTooltipLabel>
									</D1ConfiguratorTooltipItem>
								)}
							</D1ConfiguratorTooltipsWrapper>
						</D1ConfiguratorCylindoWrapper>
					</D1ConfiguratorMediaContainer>
				</div>
				<div className="col-span-full lg:col-span-5">
					<D1ConfiguratorOptionsContainer>
						<D1ConfiguratorOptionsContent>
							<D1ConfiguratorLabel>
								{subtitle}
							</D1ConfiguratorLabel>
							<D1ConfiguratorTitleGroup>
								<D1ConfiguratorTitle>
									{title}
								</D1ConfiguratorTitle>
								{selectedVariantId && (
									variants.edges
										.filter(item => item.node.id === selectedVariantId)
										.map(item => (
											<D1ConfiguratorPrice key={item.node.id}>
												{new Intl.NumberFormat(languageCode, {
													style: 'currency',
													currency: item.node.priceV2.currencyCode,
												}).format(item.node.priceV2.amount)}
											</D1ConfiguratorPrice>
										))
								)}
							</D1ConfiguratorTitleGroup>
							{products.length > 1 ? (
								<>
									<D1ConfiguratorLabel>
										{alternative_products_title}
									</D1ConfiguratorLabel>
									<D1ConfiguratorList extraPaddingBottom>
										{products?.map(({ label, Id }) => (
											<D1ConfiguratorOptionTextButton
												key={`${Id}-alternative`}
												selected={activeProduct === Id}
												onClick={() => changeProduct(Id)}
											>{label}</D1ConfiguratorOptionTextButton>
										))}
									</D1ConfiguratorList>
								</>) : null}
							<D1ConfiguratorLabel>
								{shell_label}
							</D1ConfiguratorLabel>
							<D1ConfiguratorList>
								{getMaterialOptions().map((option: SelectedOptionsProps, index) => (
									<D1ConfiguratorListItem key={option.value}>
										<D1ConfiguratorOptionButton
											onClick={() => setSelectedMaterialIndex(index)}
											selected={selectedMaterialIndex === index}
											title={option.value}
										>
											<>
												<D1ConfiguratorOptionTitle>
													{option.value}
												</D1ConfiguratorOptionTitle>
												{polster_images && (
													polster_images
														.filter(({ filename }) => getColorOptionImage(filename, option.value))
														.map(image => (
															<D1ConfiguratorOptionImage src={image.filename} alt={image.alt} key={image.id} />
														))
												)}
											</>
										</D1ConfiguratorOptionButton>
									</D1ConfiguratorListItem>
								))}
							</D1ConfiguratorList>
							<D1ConfiguratorLabel>
								{seat_label}
							</D1ConfiguratorLabel>
							<D1ConfiguratorList>
								{getColorOptionsByMaterial().map(item => (
									<D1ConfiguratorListItem key={item.node.id}>
										{item.node.selectedOptions
											.filter(option => option.name === 'Color')
											.map(option => (
												<D1ConfiguratorOptionButton
													onClick={() => setSelectedVariantId(item.node.id)}
													selected={selectedVariantId === item.node.id}
													title={option.value}
													key={option.value}
												>
													<D1ConfiguratorOptionTitle>
														{option.value}
													</D1ConfiguratorOptionTitle>
													{polster_images && (
														polster_images
															.filter(({ filename }) => getColorOptionImage(filename, option.value))
															.map(image => (
																<D1ConfiguratorOptionImage src={image.filename} alt={image.alt} key={image.id} />
															))
													)}
												</D1ConfiguratorOptionButton>
											))
										}
									</D1ConfiguratorListItem>
								))}
							</D1ConfiguratorList>

							<D1ConfiguratorActionsWrapper>
								<D1ConfiguratorLabel>
									<span>{delivery_label} </span>
									{variants.edges
										.filter(item => item.node.id === selectedVariantId)
										.map(item => (
											item.node.metafieldDelivery ? (
												<span>{item.node.metafieldDelivery.value} {delivery_time_unit}</span>
											) : (
												<span>{no_delivery_time}</span>
											)
										))
									}
								</D1ConfiguratorLabel>
								<ButtonElement disabled={isAddingItemToCart} fullWidth invert onClick={() => addToCart(selectedVariantId)}>
									{isAddingItemToCart ? (
										<ThrobberBlock black />
									) : (
										<>
											{add_to_cart_label}
										</>
									)}
								</ButtonElement>
								{process.env.GATSBY_STORE_REGION === 'eu' && (
									<div className="mt-16">
										<ButtonElement fullWidth onClick={() => showStoreLocatorVar(true)}>
											{locate_in_store}
										</ButtonElement>
									</div>
								)}
								<D1ConfiguratorDescriptionWrapper style={{ maxHeight: getDescriptionHeight }}>
									<div ref={descriptionRef}>
										<D1ConfiguratorDescription html={descriptionHtml} />
									</div>
								</D1ConfiguratorDescriptionWrapper>
								<ButtonElement
									noBorder
									noHoverEffect
									link
									withArrow
									onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
								>
									<D1ConfiguratorToggleArrow pointUp={isDescriptionExpanded}>
										<ButtonBackArrow noTransition />
									</D1ConfiguratorToggleArrow>
									<D1ConfiguratorToggleLabel>
										{isDescriptionExpanded ? readless_label : readmore_label}
									</D1ConfiguratorToggleLabel>
								</ButtonElement>
							</D1ConfiguratorActionsWrapper>
						</D1ConfiguratorOptionsContent>
					</D1ConfiguratorOptionsContainer>
				</div>
			</GridBlock>
		</D1ConfiguratorProductWrapper>
	);
};

const D1ConfiguratorModule = ({ blok }) => {
	const {
		add_to_cart_label,
		delivery_label,
		delivery_time_unit,
		no_delivery_time,
		readmore_label,
		readless_label,
		seat_label,
		shell_label,
		subtitle,
		eu_shopify_product_id,
		us_shopify_product_id,
		polster_images,
		no_product_image_label,
		locate_in_store,
		drag_label,
		zoom_label,
		alternative_products_title,
		// alternative_product_labels,
		// eu_product_ids,
		products_eu,
		products_us,
	} = blok;

	const languageCode = process.env.GATSBY_STORE_REGION === 'eu'
		? 'en-GB'
		: 'en-US';
	
	const products = process.env.GATSBY_STORE_REGION === 'eu' ? products_eu : products_us;

	if(!products || products.length === 0) return null;

	const [currentProduct, setCurrentProduct] = useState(products[0]?.Id || 0);

	const handleChangeProduct = async (id: string) => {
		if (id !== currentProduct) {
			setCurrentProduct(id);
		}
	};

	return (
		<D1ConfiguratorBlock id="d1-block-anchor">
			<GridContainer fullWidth>
				<D1ConfiguratorProductListContainer>
					{products.map((product) => (
						<D1ConfiguratorProduct
							key={`product-list-item-${product.Id}`}
							product={product}
							products={products}
							languageCode={languageCode}
							add_to_cart_label={add_to_cart_label}
							delivery_label={delivery_label}
							delivery_time_unit={delivery_time_unit}
							no_delivery_time={no_delivery_time}
							readmore_label={readmore_label}
							readless_label={readless_label}
							seat_label={seat_label}
							shell_label={shell_label}
							subtitle={subtitle}
							eu_shopify_product_id={eu_shopify_product_id}
							us_shopify_product_id={us_shopify_product_id}
							polster_images={polster_images}
							no_product_image_label={no_product_image_label}
							locate_in_store={locate_in_store}
							drag_label={drag_label}
							zoom_label={zoom_label}
							isActive={currentProduct === product.Id}
							activeProduct={currentProduct}
							handleChangeProduct={handleChangeProduct}
							alternative_products_title={alternative_products_title}
						/>
					))}
				</D1ConfiguratorProductListContainer>
			</GridContainer>
		</D1ConfiguratorBlock>
	);
};

export default D1ConfiguratorModule;
