import React from 'react';
import B1ProductHeroModule from '../b1-product-hero/module';
import B2HeroModule from '../b2-hero/module';
import C1MediaAnimationModule from '../c1-media-animation/module';
import C2ContentImageModule from '../c2-content-image/module';
import C3ContentCoverImageModule from '../c3-content-coverimage/module';
import C4MediaModule from '../c4-media/module';
import C5ContentBlockModule from '../c5-content-block/module';
import C6ContentBlockModule from '../c6-content-block/module';
import C7MediaBannerModule from '../c7-media-banner/module';
import C8HorizontalScrollModule from '../c8-horizontal-scroll/module';
import C9LinksModule from '../c9-links-module/module';
import C10SpecsModule from '../c10-specs/module';
import C11QuoteModule from '../c11-quote/module';
import C12MediaAnimationModule from '../c12-media-animation/module';
import D1ConfiguratorModule from '../d1-configurator/module';

const Components = {
	'b1-product-hero': B1ProductHeroModule,
	'b2-hero': B2HeroModule,
	'c1-media-animation': C1MediaAnimationModule,
	'c2-content-image': C2ContentImageModule,
	'c3-content-coverimage': C3ContentCoverImageModule,
	'c4-media': C4MediaModule,
	'c5-content-block': C5ContentBlockModule,
	'c6-content-block': C6ContentBlockModule,
	'c7-media-banner': C7MediaBannerModule,
	'c8-horizontal-scroll': C8HorizontalScrollModule,
	'c9-links-module': C9LinksModule,
	'c10-specs': C10SpecsModule,
	'c11-quote': C11QuoteModule,
	'c12-media-animation': C12MediaAnimationModule,
	'd1-configurator': D1ConfiguratorModule,
};

const DynamicComponent = ({ blok, fullscreenImages, constrainedImages, videos }) => {
	if (typeof Components[blok.component] !== 'undefined') {
		const Component = Components[blok.component];
		return (
			<>
				<Component
					blok={blok}
					fullscreenImages={fullscreenImages}
					constrainedImages={constrainedImages}
					videos={videos}
				/>
			</>
		);
	}
	return process.env.NODE_ENV === 'development'
		? (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
		: null;
};

export default DynamicComponent;
