import clsx from 'clsx';
import React from 'react';
import { B2MediaProps } from './types';

const B2Hero = ({ children }) => {
	return (
		<div className="b2-hero">{children}</div>
	);
};

const B2HeroMedia = ({ media, mobileMediaAlignment }: B2MediaProps) => {
	const {filename, alt} = media;
	const isVideo = filename.split('.').pop() === 'mp4';
	return (
		<>
			{isVideo ? 
				<video autoPlay playsInline muted loop className={clsx('b2-hero__media',
					{'b2-hero__media--mobile-left': mobileMediaAlignment === 'left'},
					{'b2-hero__media--mobile-right': mobileMediaAlignment === 'right'})}>
					<source src={filename} type='video/mp4' aria-controls='false'/>
				</video>
				:
				<img src={filename + '/m/'} alt={alt} className={clsx('b2-hero__media',
					{'b2-hero__media--mobile-left': mobileMediaAlignment === 'left'},
					{'b2-hero__media--mobile-right': mobileMediaAlignment === 'right'})}/>
			}
		</>
	);
};
const B2HeroTitle = ({ children }) => <h1 className='b2-hero__title'>{children}</h1>;
const B2HeroSubtitle = ({ children }) => <p className='b2-hero__subtitle'>{children}</p>;
const B2HeroTextWrapper = ({ children }) => <section className='b2-hero__text-wrapper'>{children}</section>;

export {
	B2Hero,
	B2HeroMedia,
	B2HeroSubtitle,
	B2HeroTextWrapper,
	B2HeroTitle,
};
