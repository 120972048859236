import React from 'react';
import { C3ContentCoverImageModuleProps } from './types';
import {
	C3ContentCoverImageBlock,
	C3ContentCoverImageWrapper,
	C3ContentCoverImageContent,
	C3ContentCoverImageTitle,
	C3ContentCoverImageDescription,
	C3ContentCoverImageContentWrapper,
	C3ContentCoverImageExternalLink,
} from './index';
import {
	GridBlock,
	GridContainer,
} from '../grid';
import {
	InternalLinkButton,
	ButtonAnchorWrapper,
} from '../button';

const C3ContentCoverImageModule = ({ blok }: C3ContentCoverImageModuleProps) => {
	const {
		title,
		description,
		image,
		link_label,
		link_url,
		image_right,
	} = blok;

	const getLink = () => {
		return link_url.url && link_label ? (
			<ButtonAnchorWrapper black>
				<C3ContentCoverImageExternalLink url={link_url.url}>
					{link_label}
				</C3ContentCoverImageExternalLink>
			</ButtonAnchorWrapper>
		) : (
			<ButtonAnchorWrapper black>
				<InternalLinkButton noBorder black to={'/' + link_url.cached_url}>
					{link_label}
				</InternalLinkButton>
			</ButtonAnchorWrapper>
		);
	};

	return (
		<C3ContentCoverImageBlock>
			<GridContainer fullWidth>
				<GridBlock>
					{image_right
						?
						<>
							<div className="col-span-full lg:col-span-6">
								<C3ContentCoverImageContentWrapper>
									<C3ContentCoverImageContent>
										<C3ContentCoverImageTitle>
											{title}
										</C3ContentCoverImageTitle>
										<C3ContentCoverImageDescription text={description} />
										{link_label && getLink()}
									</C3ContentCoverImageContent>
								</C3ContentCoverImageContentWrapper>
							</div>
							<div className="relative col-span-full lg:col-span-6 lg:-ml-8">
								<C3ContentCoverImageWrapper
									imageData={image}
								/>
							</div>
						</>
						:
						<>
							<div className="relative col-span-full lg:col-span-6 lg:-mr-8">
								<C3ContentCoverImageWrapper
									imageData={image}
								/>
							</div>
							<div className="col-span-full lg:col-span-6">
								<C3ContentCoverImageContentWrapper>
									<C3ContentCoverImageContent>
										<C3ContentCoverImageTitle>
											{title}
										</C3ContentCoverImageTitle>
										<C3ContentCoverImageDescription text={description} />
										{link_label && getLink()}
									</C3ContentCoverImageContent>
								</C3ContentCoverImageContentWrapper>
							</div>
						</>
					}
				</GridBlock>
			</GridContainer>
		</C3ContentCoverImageBlock>
	);
};

export default C3ContentCoverImageModule;
