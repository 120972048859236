import clsx from 'clsx';
import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { A2FooterColumnProps, A2FooterLinkProps } from './types';

const A2FooterBlock = ({ children }) => (
	<footer className="a2-footer">
		{children}
	</footer>
);

const A2FooterColumn = ({ children, last }: PropsWithChildren<A2FooterColumnProps>) => (
	<div className={clsx('a2-footer__column', {'a2-footer__column--last': last})}>
		{children}
	</div>
);


const A2FooterLink = ({ linkItem, styleLikeButton }: A2FooterLinkProps) => {
	return (
		linkItem.url.url ? (
			<a className={clsx({'a2-footer__link': !styleLikeButton}, {'button button--fullwidth': styleLikeButton})}
				href={linkItem.url.url}
				target="_blank"
				rel="noreferrer noopener">
				{linkItem.label}
			</a>
		)	:	(
			linkItem.url.cached_url && 
				<Link className={clsx({'a2-footer__link': !styleLikeButton}, {'button button--fullwidth': styleLikeButton})} to={`/${linkItem.url.cached_url}`}>
					{linkItem.label}
				</Link>
		)
	);
};

const A2FooterLinkContainer = ({ children }) => (
	<div className="a2-footer__link-container">
		{children}
	</div>
);

const A2FooterTitle = ({ children }) => (
	<p className="a2-footer__title">{children}</p>
);


export {
	A2FooterBlock,
	A2FooterColumn,
	A2FooterLink,
	A2FooterLinkContainer,
	A2FooterTitle,
};
