import React from 'react';
import { InternalLinkButton } from '../button';
import { useFileSource } from '../../hooks';

import {
	C8DetailImageProps,
	C8DesignerProps,
	C8FullHeightMediaProps,
	C8ReadMoreProps,
	C8FullHeightImageProps,
	C8FullHeightVideoProps,
	C8BigTextProps,
} from './types';

const C8HorizontalScrollWrapper = ({ children }) => <div className='c8-horizontal-scroll'>{children}</div>;
const C8Section = ({ children }) => <section className='c8-horizontal-scroll__section'>{children}</section>;

const C8DesignerImage = ({ image }) => {
	return (
		<>
			{image &&
				<img src={image.filename + '/m/'} className="c8-horizontal-scroll__designer-image" alt={image.alt} />}
		</>
	);
};

const C8Title = ({ children }) => <h2 className='c8-horizontal-scroll__title'>{children}</h2>;
const C8Text = ({ children }) => <p className='c8-horizontal-scroll__text'>{children}</p>;
const C8DesignerTextWrapper = ({ children }) => <div className='c8-horizontal-scroll__text-wrapper'>{children}</div>;
const C8DetailsWrapper = ({ children }) => <div className='c8-horizontal-scroll__details-wrapper'>{children}</div>;

const C8DetailsText = ({ children }) => <p className='c8-horizontal-scroll__details-text'>{children}</p>;
const C8DetailsSubtitle = ({ children }) => <h3 className='c8-horizontal-scroll__details-subtitle'>{children}</h3>;
const C8ImageContainer = ({ children }) => <div className='c8-horizontal-scroll__image-container'>{children}</div>;
const C8ImageFigure = ({ children }) => <figure className='c8-horizontal-scroll__image-figure'>{children}</figure>;
const C8DetailImage = ({ image, text, image_label }: C8DetailImageProps) => {
	return (
		<C8DetailsWrapper>
			<C8ImageContainer>
				<C8DetailsImage image={image}/>
				{image_label && <C8DetailsSubtitle>{image_label}</C8DetailsSubtitle>}
			</C8ImageContainer>
			<C8DetailsText>{text}</C8DetailsText>
		</C8DetailsWrapper>
	);
};

const C8DetailsImage = ({ image }) => {
	return (
		<>
			{image?.filename &&
				<img src={image.filename} className='c8-horizontal-scroll__details-image' alt={image?.alt} />
			}
		</>
	);
};

const FullHeightImage = ({ image }: C8FullHeightImageProps) => {

	return (
		<>
			{image?.filename &&
				<img src={image.filename + '/m/'} className='c8-horizontal-scroll__full-height-image' alt={image?.alt}/>
			}
		</>
	);
};

const FullHeightVideo = ({ filename, videoAssets }: C8FullHeightVideoProps) => {
	const videoFile = useFileSource(videoAssets, filename);
	return (
		<video className='c8-horizontal-scroll__full-height-media' autoPlay loop muted playsInline src={videoFile ? videoFile.node.publicUrl : filename} />
	);
};

const C8FullHeightMedia = ({ media, videoAssets }: C8FullHeightMediaProps) => {
	const isVideo = media ? media.filename.substring(media.filename.lastIndexOf('.') + 1) === 'mp4' : null;
	return (
		<div className='c8-horizontal-scroll__full-height-media'>
			{isVideo ?
				<FullHeightVideo filename={media?.filename} videoAssets={videoAssets} /> :
				<FullHeightImage image={media} />
			}
		</div>
	);
};

const C8Designer = ({ image, title, text }: C8DesignerProps) => {
	return (
		<div className='c8-horizontal-scroll__designer'>
			<C8ImageFigure>
				<C8DesignerImage image={image} />
			</C8ImageFigure>
			<C8DesignerTextWrapper>
				<C8Title>{title}</C8Title>
				<C8Text>{text}</C8Text>
			</C8DesignerTextWrapper>
		</div>
	);
};

const C8BigText = ({ text }: C8BigTextProps) => <div className='c8-horizontal-scroll__big-text'>{text}</div>;

const C8ReadMore = ({
	image1,
	image2,
	text,
	link: { cached_url },
	link_label,
}: C8ReadMoreProps) => {

	return	(
		<div className='c8-horizontal-scroll__more'>
			<div className='c8-horizontal-scroll__more-inner'>
				<figure className='c8-horizontal-scroll__more-image-holder c8-horizontal-scroll__more-image-holder--first'>
					{image1?.filename &&
						<img className='c8-horizontal-scroll__more-image' src={image1.filename + '/m/'} alt={image1?.alt} />
					}
				</figure>
				<figure className='c8-horizontal-scroll__more-image-holder c8-horizontal-scroll__more-image-holder--second'>
					{image2?.filename &&
						<img className='c8-horizontal-scroll__more-image' src={image2.filename + '/m/'} alt={image2?.alt} />
					}
				</figure>
				<div className='c8-horizontal-scroll__more-text-wrapper'>
					<p className='c8-horizontal-scroll__more-text'>{text}</p>
					{link_label && (
						<div className='c8-horizontal-scroll__anchor-point'>
							<InternalLinkButton showCurtainAnimation noHoverEffect noBorder to={`/${cached_url}`}>{link_label}</InternalLinkButton>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export {
	C8Section,
	C8HorizontalScrollWrapper,
	C8DetailsWrapper,
	C8DetailImage,
	C8FullHeightMedia,
	C8Designer,
	C8BigText,
	C8ReadMore,
};
