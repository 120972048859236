import React, { PropsWithChildren } from 'react';

import { C7MediaBanner, C7MediaBannerImage, C7MediaBannerVideo } from '.';
import { MediaBannerBlokProps } from './types';

const C7MediaBannerModule =
	({ blok: {
		has_frame,
		freeze_on_video_end,
		is_fixed_to_background,
		image,
		video,
	},
	videos,
	}
: PropsWithChildren<MediaBannerBlokProps>) => (
		<C7MediaBanner hasFrame={has_frame}>
			{video?.filename && <C7MediaBannerVideo filename={video.filename} assets={videos} fixed={is_fixed_to_background} freezeOnVideoEnd={freeze_on_video_end} />}
			{image && !video?.filename && <C7MediaBannerImage filename={image.filename} alt={image.alt} />}
		</C7MediaBanner>
	);

export default C7MediaBannerModule;
