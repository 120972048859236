import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import {
	B1ProductHeroImageProps,
	B1ProductHeroImageWrapperProps,
} from './types';

const B1ProductHeroBlock = ({ children }: PropsWithChildren<unknown>) => (
	<div className="b1-product-hero">
		{children}
	</div>
);

const B1ProductHeroImageWrapper = ({ children, background }: PropsWithChildren<B1ProductHeroImageWrapperProps>) => (
	<div
		className={clsx('b1-product-hero__image-wrapper', {
			'b1-product-hero__image-wrapper--background': background,
		})}
	>
		{children}
	</div>
);

const B1ProductHeroImage = ({ imageData }: PropsWithChildren<B1ProductHeroImageProps>) => {
	const { filename, alt } = imageData;

	return (
		<div className="b1-product-hero__image-cover">
			{filename ? (
				<img src={filename} alt={alt} className="b1-product-hero__image" />
			) : null}
		</div>
	);
};

const B1ProductHeroTitleGroup = ({ children }: PropsWithChildren<unknown>) => (
	<div className="b1-product-hero__title-group">
		{children}
	</div>
);

const B1ProductHeroSubtitle = ({ children }: PropsWithChildren<unknown>) => (
	<span className="b1-product-hero__subtitle">
		{children}
	</span>
);

const B1ProductHeroTitle = ({ children }: PropsWithChildren<unknown>) => (
	<h1 className="b1-product-hero__title">
		{children}
	</h1>
);

const B1ProductHeroContentWrapper = ({ children }: PropsWithChildren<unknown>) => (
	<div className="b1-product-hero__content-wrapper">
		{children}
	</div>
);

const B1ProductHeroContent = ({ children }: PropsWithChildren<unknown>) => (
	<div className="b1-product-hero__content">
		{children}
	</div>
);

const B1ProductHeroContainer = ({ children }: PropsWithChildren<unknown>) => (
	<div className="b1-product-hero__container">
		{children}
	</div>
);

const B1ProductHeroDescription = ({ children }: PropsWithChildren<unknown>) => (
	<p className="b1-product-hero__description">
		{children}
	</p>
);

const B1ProductHeroBottom = ({ children }: PropsWithChildren<unknown>) => (
	<div className="b1-product-hero__bottom">
		{children}
	</div>
);

const B1ProductHeroScrollIndicator = ({ children }: PropsWithChildren<unknown>) => (
	<div className="b1-product-hero__scroll-indicator">
		{children}
	</div>
);

export {
	B1ProductHeroBlock,
	B1ProductHeroImageWrapper,
	B1ProductHeroImage,
	B1ProductHeroTitleGroup,
	B1ProductHeroSubtitle,
	B1ProductHeroTitle,
	B1ProductHeroContentWrapper,
	B1ProductHeroContainer,
	B1ProductHeroDescription,
	B1ProductHeroContent,
	B1ProductHeroBottom,
	B1ProductHeroScrollIndicator,
};
