import React from 'react';
import clsx from 'clsx';
import { RichTextBlockProps } from './types';

const RichText = ({ html, className }: RichTextBlockProps) => {
	return (
		<div
			dangerouslySetInnerHTML={{ __html: html }}
			className={clsx(['richtext', className])}
		/>
	);
};

export default RichText;
