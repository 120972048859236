import React from 'react';
import {
	B2Hero,
	B2HeroMedia,
	B2HeroSubtitle,
	B2HeroTextWrapper,
	B2HeroTitle,
} from './';

import { B2HeroModuleProps } from './types';

const B2HeroModule = ({ blok: { media, subtitle, title, mobile_media_alignment } }: B2HeroModuleProps) => (
	<B2Hero>
		<B2HeroMedia media={media} mobileMediaAlignment={mobile_media_alignment} />
		<B2HeroTextWrapper>
			<B2HeroSubtitle>{subtitle}</B2HeroSubtitle>
			<B2HeroTitle >{title}</B2HeroTitle>
		</B2HeroTextWrapper>
	</B2Hero>
);

export default B2HeroModule;
