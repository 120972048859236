import clsx from 'clsx';
import React from 'react';

const C11QuoteBlock = ({ noPaddingBottom, children }) => <div className={clsx('c11-quote', {'c11-quote--no-padding-bottom': noPaddingBottom})}>{children}</div>;
const C11QuoteFigure = ({ children }) => <figure>{children}</figure>;
const C11QuoteText = ({ children }) => <blockquote className='c11-quote__text'>“{children}”</blockquote>;
const C11QuoteFigCaption = ({ children }) => <figcaption className='c11-quote__caption'>{children}</figcaption>;

export {
	C11QuoteBlock,
	C11QuoteFigure,
	C11QuoteText,
	C11QuoteFigCaption,
};
